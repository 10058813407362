import React from "react";
import { Headings } from "../../components";
import { data } from "../../constants";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import { motion } from "framer-motion";

import "./Services.css";

const Services = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.5,
      }}
      viewport={{ once: true }}
      id="services"
      className="d-block pt-md-4"
    >
      <Headings
        title="Services"
        text="At our digital marketing agency, we offer a range of services to help businesses grow and succeed online. These services include"
      />

      <div className="row">
        {data.ServicesData.map(
          ({ titleone, titletwo, link, itemclass, imgURL }, index) => (
            <motion.div
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 1,
                delay: 0.3 + index * 0.1,
              }}
              viewport={{ once: true }}
              className="col-lg-6 col-12"
              key={index}
            >
              <div className={`row ${itemclass}`}>
                <div className="col-md-6 box">
                  <div>
                    {" "}
                    <span>{titleone} </span>
                    <span>{titletwo}</span>
                  </div>
                  <a href={link} alt={titleone} className="readmore">
                    {" "}
                    <BsFillArrowUpRightCircleFill /> Learn more{" "}
                  </a>
                </div>
                <div className="col-md-6 text-end">
                  <img
                    src={imgURL}
                    alt={titleone}
                    className="img-fluid img-services"
                  />
                </div>
              </div>
            </motion.div>
          )
        )}
      </div>
    </motion.div>
  );
};

export default Services;
