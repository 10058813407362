import React from "react";
import { Headings, IconScroll } from "../../components";
import { data } from "../../constants";
import "./CaseStudies.css";
import { motion } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";

const CaseStudies = () => {
  return (
    <motion.div
      initial={{
        x: "0%",
        y: -100,
        opacity: 0,
      }}
      whileInView={{
        x: "0%",
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.7,
      }}
      viewport={{ once: true }}
      className="section-padding"
      id="projects"
    >
      <Headings
        title="Our Projects"
        text="Explore real-life examples of our proven digital marketing success through our projects."
      />

      <div className="case-studies">
        {data.CaseStudies.map(({ text, link }, index) => (
          <div key={index} className="case-studies-item">
            <p>{text}</p>
            <a href={link} alt="Learn more">
              Learn more <FiArrowUpRight />
            </a>
          </div>
        ))}
      </div>

      <IconScroll />
    </motion.div>
  );
};

export default CaseStudies;
