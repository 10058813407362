import React from "react";
import { motion } from "framer-motion";
import "./Slider.css";

function Slider1() {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.8,
      }}
      viewport={{ once: true }}
      className="parent"
    >
      <div className="logos">
        <div className="logos-slider">
          <span>Don't risk your budget</span>
          <span>Trust the professionals</span>
          <span>Don't risk your budget</span>
          <span>Trust the professionals</span>
          <span>Don't risk your budget</span>
          <span>Trust the professionals</span>
        </div>
        <div className="logos-slider">
          <span>Don't risk your budget</span>
          <span>Trust the professionals</span>
          <span>Don't risk your budget</span>
          <span>Trust the professionals</span>
          <span>Don't risk your budget</span>
          <span>Trust the professionals</span>
        </div>
      </div>
    </motion.div>
  );
}

export default Slider1;
