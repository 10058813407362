import React from "react";
import { motion } from "framer-motion";
import "./Slider.css";

function Slider() {
  return (
    <motion.div
      initial={{
        y: -100,
        x: "0%",
        opacity: 0,
      }}
      whileInView={{
        y: 0,
        x: "0%",
        opacity: 1,
      }}
      transition={{ delay: 0.35 }}
      viewport={{ once: true }}
      className="parent"
    >
      <div className="logos">
        <div className="logos-slider">
          <span>Search engine optimization</span>
          <span>Web Design & Web Development</span>
          <span>Social Media Marketing</span>
          <span>Email Marketing</span>
          <span>Content Creation</span>
          <span>Analytics and Tracking</span>
        </div>
        <div className="logos-slider">
          <span>Search engine optimization</span>
          <span>Web Design & Web Development</span>
          <span>Social Media Marketing</span>
          <span>Email Marketing</span>
          <span>Content Creation</span>
          <span>Analytics and Tracking</span>
        </div>
      </div>
    </motion.div>
  );
}

export default Slider;
