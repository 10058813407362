import React from "react";
import { Headings } from "../../components";
import { data } from "../../constants";
import Carousel from "react-bootstrap/Carousel";
import { motion } from "framer-motion";
import "./Testimonials.css";

const Testimonials = () => {
  return (
    <motion.div
      initial={{
        x: "0%",
        y: -100,
        opacity: 0,
      }}
      whileInView={{
        x: "0%",
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.75,
      }}
      viewport={{ once: true }}
      className="section-padding"
      id="testimonials"
    >
      <Headings
        title="Testimonials"
        text="Hear from Our Satisfied Clients: Read Our Testimonials to Learn More about Our Digital Marketing Services"
      />

      <div className="carousel-bg">
        <Carousel>
          {data.Testimonials.map((item, index) => (
            <Carousel.Item key={index} className="carousel-box" interval={6000}>
              <Carousel.Caption>
                <p className="speech-bubble"> {item.testimonial}</p>
                <div className="carousel-name">{item.name}</div>
                <div className="carousel-position">{item.position}</div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </motion.div>
  );
};

export default Testimonials;
