import React from "react";
import { data } from "../../constants";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { motion } from "framer-motion";
import { BsInstagram, BsWhatsapp, BsLinkedin } from "react-icons/bs";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import "./Menu.css";
import { gtagSendEvent } from "../../utils/gtaghelper";

const Menu = () => {
  return (
    <motion.div
      initial={{ y: -100, x: "0%", opacity: 0 }}
      animate={{ y: 0, x: "0%", opacity: 1 }}
    >
      <div
        className="banner"
        style={{
          backgroundColor: "#B9FF66",
          color: "black",
          textAlign: "center",
          padding: "10px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <a
              style={{ color: "black" }}
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/quantech.pro/"
            >
              <BsInstagram />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              style={{ color: "black" }}
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send/?phone=916363053425&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0"
            >
              <BsWhatsapp />
            </a>
          </div>
          <div>
            {" "}
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="tel:+91-6363053425"
              onClick={(e) => {
                e.preventDefault(); // Prevent the default link behavior
                gtagSendEvent("conversion_event_contact", "tel:+91-6363053425"); // Call the gtag function
                
                // Use setTimeout to delay the call slightly to ensure gtag processes
                // Adjust the delay if necessary
              }}
            >
              <FaPhoneAlt /> &nbsp; +91-6363053425
            </a>

          </div>
          <div>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="mailto:ikiranrockrzz@gmail.com"
            >
              <FaEnvelope /> &nbsp; ikiranrockrzz@gmail.com
            </a>
          </div>
        </div>
      </div>
      <Navbar collapseOnSelect expand="lg" bg="ligth" variant="ligth">
        <Container>
          <Navbar.Brand href="/#home">
            <h1
              style={{
                fontSize: "50px",
                textDecorationColor: "#b9ff66",
                textDecorationLine: "underline",
              }}
            >
              Quantech
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              {data.Menu.map((item, index) => (
                <Nav.Link key={index} href={item.link}>
                  {item.text}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </motion.div>
  );
};

export default Menu;
