export const gtagSendEvent = (eventName, url) => {
    const callback = function () {
      if (typeof url === 'string') {
        window.location = url;
      }
    };
    
    window.gtag('event', eventName, {
      'event_callback': callback,
      'event_timeout': 2000,
      // <event_parameters> can be added here
    });
  };