import React from "react";
import "./partners.css";
import { Headings } from "../../components";
import meta from "../../assets/meta.png";
import google from "../../assets/google.png";
import shopify from "../../assets/shopify.svg";
import wix from "../../assets/wix.svg";
import amazon from "../../assets/amazon.png";
import walmart from "../../assets/walmart.png";
import wordpress from "../../assets/wordpress.png";
import { motion } from "framer-motion";

function Partners() {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.7,
      }}
      viewport={{ once: true }}
    >
      <div className="wrapper">
        <Headings title="Partners" text={"We work with the best of best"} />
      </div>
      <div className="parent">
        <img width={"140px"} src={meta} alt="" />
        <img width={"100px"} src={google} alt="" />
        <img width={"130px"} src={shopify} alt="" />
        <img width={"140px"} src={wordpress} alt="" />
        <img width={"120px"} src={wix} alt="" />
        <img width={"130px"} src={amazon} alt="" />
        <img width={"130px"} src={walmart} alt="" />
      </div>
    </motion.div>
  );
}

export default Partners;
