import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { data } from "../../constants";
import { Headings } from "../../components";
import { motion } from "framer-motion";
import "./Process.css";

const Process = () => {
  return (
    <motion.div
      initial={{
        x: "0%",
        y: -100,
        opacity: 0,
      }}
      whileInView={{
        x: "0%",
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.7,
      }}
      viewport={{ once: true }}
      className="section-padding"
      id="process"
    >
      <Headings
        title="Our Working Process "
        text="Step-by-Step Guide to Achieving Your Business Goals"
      />

      <Accordion>
        {data.WorkingProcess.map((item, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                whileInView={{
                  opacity: 1,
                }}
                transition={{
                  duration: 1,
                  delay: 0.4 + index * 0.12,
                }}
                viewport={{ once: true }}
              >
                <span>{index + 1}</span> {item.title}
              </motion.div>
            </Accordion.Header>
            <Accordion.Body>
              <p> {item.description} </p>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </motion.div>
  );
};

export default Process;
