import React from "react";
import { Headings } from "../../components";
import { data } from "../../constants";
import { motion } from "framer-motion";
import "./Team.css";

const Team = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.7,
      }}
      viewport={{ once: true }}
      className="section-padding"
    >
      <Headings
        title="Common Marketing Hurdles"
        text="Holding You Back?
        Let's Get You Back on Track!"
      />

      <div className="row">
        {data.Team.map(({ name, position, info, foto, linkedin }, index) => (
          <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team" key={index}>
              <div className="card-team-header">
                {" "}
                {/*  <img src={foto} alt={name} /> */}
                <p>
                  {" "}
                  <span>{name}</span>
                </p>
              </div>
              <p>{info}</p>
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default Team;
