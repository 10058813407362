import React from "react";
import { Headings } from "../../components";
import "./Contact.css";
import { motion } from "framer-motion";

const Contact = () => {
  const sendWhatsAppMessage = (event) => {
    event.preventDefault(); // Prevent form submission
    let name = document.getElementById("name").value;
    let email = document.getElementById("email").value;
    let message = document.getElementById("message").value;
    let phoneNumber = "+916363053425"; // Your WhatsApp number

    // Construct the WhatsApp message
    let whatsappMessage = `Hi, my name is ${name}. My email address is ${email}. Message: ${message}`;

    // Encode the message for use in a URL
    let encodedMessage = encodeURIComponent(whatsappMessage);

    // Open a WhatsApp chat window with the specified number and message
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodedMessage}`,
      "_blank"
    );
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.925,
      }}
      viewport={{ once: true }}
      className="section-padding"
    >
      <Headings
        title="Contact Us"
        text="Connect with Us: Let's  Discuss Your Digital Marketing Needs"
      />

      <div className="contact" id="quote">
        <div className="row">
          <div className="col-md-6 col-12">
            <h2>Enter Your Details</h2>
            <form onSubmit={sendWhatsAppMessage}>
              <div className="form-group">
                <label>Name*</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  id="name"
                  required
                />
              </div>
              <div className="form-group">
                <label>Email*</label>
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  id="email"
                  required
                />
              </div>
              <div className="form-group">
                <label>Message*</label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="3"
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn-positivus w-100">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
